(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/libs/jotai-utils/assets/javascripts/jotai-utils-development.js') >= 0) return;  svs.modules.push('/components/libs/jotai-utils/assets/javascripts/jotai-utils-development.js');
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports, require('jotai'), require('react')) :
  typeof define === 'function' && define.amd ? define(['exports', 'jotai', 'react'], factory) :
  (global = typeof globalThis !== 'undefined' ? globalThis : global || self, factory(global.jotaiUtils = {}, global.jotai, global.React));
})(this, (function (exports, jotai, react) { 'use strict';

  var RESET = Symbol();

  function atomWithReset(initialValue) {
    var anAtom = jotai.atom(initialValue, function (get, set, update) {
      var nextValue = typeof update === 'function' ? update(get(anAtom)) : update;
      set(anAtom, nextValue === RESET ? initialValue : nextValue);
    });
    return anAtom;
  }

  function _regeneratorRuntime() {
    _regeneratorRuntime = function () {
      return exports;
    };
    var exports = {},
      Op = Object.prototype,
      hasOwn = Op.hasOwnProperty,
      defineProperty = Object.defineProperty || function (obj, key, desc) {
        obj[key] = desc.value;
      },
      $Symbol = "function" == typeof Symbol ? Symbol : {},
      iteratorSymbol = $Symbol.iterator || "@@iterator",
      asyncIteratorSymbol = $Symbol.asyncIterator || "@@asyncIterator",
      toStringTagSymbol = $Symbol.toStringTag || "@@toStringTag";
    function define(obj, key, value) {
      return Object.defineProperty(obj, key, {
        value: value,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }), obj[key];
    }
    try {
      define({}, "");
    } catch (err) {
      define = function (obj, key, value) {
        return obj[key] = value;
      };
    }
    function wrap(innerFn, outerFn, self, tryLocsList) {
      var protoGenerator = outerFn && outerFn.prototype instanceof Generator ? outerFn : Generator,
        generator = Object.create(protoGenerator.prototype),
        context = new Context(tryLocsList || []);
      return defineProperty(generator, "_invoke", {
        value: makeInvokeMethod(innerFn, self, context)
      }), generator;
    }
    function tryCatch(fn, obj, arg) {
      try {
        return {
          type: "normal",
          arg: fn.call(obj, arg)
        };
      } catch (err) {
        return {
          type: "throw",
          arg: err
        };
      }
    }
    exports.wrap = wrap;
    var ContinueSentinel = {};
    function Generator() {}
    function GeneratorFunction() {}
    function GeneratorFunctionPrototype() {}
    var IteratorPrototype = {};
    define(IteratorPrototype, iteratorSymbol, function () {
      return this;
    });
    var getProto = Object.getPrototypeOf,
      NativeIteratorPrototype = getProto && getProto(getProto(values([])));
    NativeIteratorPrototype && NativeIteratorPrototype !== Op && hasOwn.call(NativeIteratorPrototype, iteratorSymbol) && (IteratorPrototype = NativeIteratorPrototype);
    var Gp = GeneratorFunctionPrototype.prototype = Generator.prototype = Object.create(IteratorPrototype);
    function defineIteratorMethods(prototype) {
      ["next", "throw", "return"].forEach(function (method) {
        define(prototype, method, function (arg) {
          return this._invoke(method, arg);
        });
      });
    }
    function AsyncIterator(generator, PromiseImpl) {
      function invoke(method, arg, resolve, reject) {
        var record = tryCatch(generator[method], generator, arg);
        if ("throw" !== record.type) {
          var result = record.arg,
            value = result.value;
          return value && "object" == typeof value && hasOwn.call(value, "__await") ? PromiseImpl.resolve(value.__await).then(function (value) {
            invoke("next", value, resolve, reject);
          }, function (err) {
            invoke("throw", err, resolve, reject);
          }) : PromiseImpl.resolve(value).then(function (unwrapped) {
            result.value = unwrapped, resolve(result);
          }, function (error) {
            return invoke("throw", error, resolve, reject);
          });
        }
        reject(record.arg);
      }
      var previousPromise;
      defineProperty(this, "_invoke", {
        value: function (method, arg) {
          function callInvokeWithMethodAndArg() {
            return new PromiseImpl(function (resolve, reject) {
              invoke(method, arg, resolve, reject);
            });
          }
          return previousPromise = previousPromise ? previousPromise.then(callInvokeWithMethodAndArg, callInvokeWithMethodAndArg) : callInvokeWithMethodAndArg();
        }
      });
    }
    function makeInvokeMethod(innerFn, self, context) {
      var state = "suspendedStart";
      return function (method, arg) {
        if ("executing" === state) throw new Error("Generator is already running");
        if ("completed" === state) {
          if ("throw" === method) throw arg;
          return doneResult();
        }
        for (context.method = method, context.arg = arg;;) {
          var delegate = context.delegate;
          if (delegate) {
            var delegateResult = maybeInvokeDelegate(delegate, context);
            if (delegateResult) {
              if (delegateResult === ContinueSentinel) continue;
              return delegateResult;
            }
          }
          if ("next" === context.method) context.sent = context._sent = context.arg;else if ("throw" === context.method) {
            if ("suspendedStart" === state) throw state = "completed", context.arg;
            context.dispatchException(context.arg);
          } else "return" === context.method && context.abrupt("return", context.arg);
          state = "executing";
          var record = tryCatch(innerFn, self, context);
          if ("normal" === record.type) {
            if (state = context.done ? "completed" : "suspendedYield", record.arg === ContinueSentinel) continue;
            return {
              value: record.arg,
              done: context.done
            };
          }
          "throw" === record.type && (state = "completed", context.method = "throw", context.arg = record.arg);
        }
      };
    }
    function maybeInvokeDelegate(delegate, context) {
      var method = delegate.iterator[context.method];
      if (undefined === method) {
        if (context.delegate = null, "throw" === context.method) {
          if (delegate.iterator.return && (context.method = "return", context.arg = undefined, maybeInvokeDelegate(delegate, context), "throw" === context.method)) return ContinueSentinel;
          context.method = "throw", context.arg = new TypeError("The iterator does not provide a 'throw' method");
        }
        return ContinueSentinel;
      }
      var record = tryCatch(method, delegate.iterator, context.arg);
      if ("throw" === record.type) return context.method = "throw", context.arg = record.arg, context.delegate = null, ContinueSentinel;
      var info = record.arg;
      return info ? info.done ? (context[delegate.resultName] = info.value, context.next = delegate.nextLoc, "return" !== context.method && (context.method = "next", context.arg = undefined), context.delegate = null, ContinueSentinel) : info : (context.method = "throw", context.arg = new TypeError("iterator result is not an object"), context.delegate = null, ContinueSentinel);
    }
    function pushTryEntry(locs) {
      var entry = {
        tryLoc: locs[0]
      };
      1 in locs && (entry.catchLoc = locs[1]), 2 in locs && (entry.finallyLoc = locs[2], entry.afterLoc = locs[3]), this.tryEntries.push(entry);
    }
    function resetTryEntry(entry) {
      var record = entry.completion || {};
      record.type = "normal", delete record.arg, entry.completion = record;
    }
    function Context(tryLocsList) {
      this.tryEntries = [{
        tryLoc: "root"
      }], tryLocsList.forEach(pushTryEntry, this), this.reset(!0);
    }
    function values(iterable) {
      if (iterable) {
        var iteratorMethod = iterable[iteratorSymbol];
        if (iteratorMethod) return iteratorMethod.call(iterable);
        if ("function" == typeof iterable.next) return iterable;
        if (!isNaN(iterable.length)) {
          var i = -1,
            next = function next() {
              for (; ++i < iterable.length;) if (hasOwn.call(iterable, i)) return next.value = iterable[i], next.done = !1, next;
              return next.value = undefined, next.done = !0, next;
            };
          return next.next = next;
        }
      }
      return {
        next: doneResult
      };
    }
    function doneResult() {
      return {
        value: undefined,
        done: !0
      };
    }
    return GeneratorFunction.prototype = GeneratorFunctionPrototype, defineProperty(Gp, "constructor", {
      value: GeneratorFunctionPrototype,
      configurable: !0
    }), defineProperty(GeneratorFunctionPrototype, "constructor", {
      value: GeneratorFunction,
      configurable: !0
    }), GeneratorFunction.displayName = define(GeneratorFunctionPrototype, toStringTagSymbol, "GeneratorFunction"), exports.isGeneratorFunction = function (genFun) {
      var ctor = "function" == typeof genFun && genFun.constructor;
      return !!ctor && (ctor === GeneratorFunction || "GeneratorFunction" === (ctor.displayName || ctor.name));
    }, exports.mark = function (genFun) {
      return Object.setPrototypeOf ? Object.setPrototypeOf(genFun, GeneratorFunctionPrototype) : (genFun.__proto__ = GeneratorFunctionPrototype, define(genFun, toStringTagSymbol, "GeneratorFunction")), genFun.prototype = Object.create(Gp), genFun;
    }, exports.awrap = function (arg) {
      return {
        __await: arg
      };
    }, defineIteratorMethods(AsyncIterator.prototype), define(AsyncIterator.prototype, asyncIteratorSymbol, function () {
      return this;
    }), exports.AsyncIterator = AsyncIterator, exports.async = function (innerFn, outerFn, self, tryLocsList, PromiseImpl) {
      void 0 === PromiseImpl && (PromiseImpl = Promise);
      var iter = new AsyncIterator(wrap(innerFn, outerFn, self, tryLocsList), PromiseImpl);
      return exports.isGeneratorFunction(outerFn) ? iter : iter.next().then(function (result) {
        return result.done ? result.value : iter.next();
      });
    }, defineIteratorMethods(Gp), define(Gp, toStringTagSymbol, "Generator"), define(Gp, iteratorSymbol, function () {
      return this;
    }), define(Gp, "toString", function () {
      return "[object Generator]";
    }), exports.keys = function (val) {
      var object = Object(val),
        keys = [];
      for (var key in object) keys.push(key);
      return keys.reverse(), function next() {
        for (; keys.length;) {
          var key = keys.pop();
          if (key in object) return next.value = key, next.done = !1, next;
        }
        return next.done = !0, next;
      };
    }, exports.values = values, Context.prototype = {
      constructor: Context,
      reset: function (skipTempReset) {
        if (this.prev = 0, this.next = 0, this.sent = this._sent = undefined, this.done = !1, this.delegate = null, this.method = "next", this.arg = undefined, this.tryEntries.forEach(resetTryEntry), !skipTempReset) for (var name in this) "t" === name.charAt(0) && hasOwn.call(this, name) && !isNaN(+name.slice(1)) && (this[name] = undefined);
      },
      stop: function () {
        this.done = !0;
        var rootRecord = this.tryEntries[0].completion;
        if ("throw" === rootRecord.type) throw rootRecord.arg;
        return this.rval;
      },
      dispatchException: function (exception) {
        if (this.done) throw exception;
        var context = this;
        function handle(loc, caught) {
          return record.type = "throw", record.arg = exception, context.next = loc, caught && (context.method = "next", context.arg = undefined), !!caught;
        }
        for (var i = this.tryEntries.length - 1; i >= 0; --i) {
          var entry = this.tryEntries[i],
            record = entry.completion;
          if ("root" === entry.tryLoc) return handle("end");
          if (entry.tryLoc <= this.prev) {
            var hasCatch = hasOwn.call(entry, "catchLoc"),
              hasFinally = hasOwn.call(entry, "finallyLoc");
            if (hasCatch && hasFinally) {
              if (this.prev < entry.catchLoc) return handle(entry.catchLoc, !0);
              if (this.prev < entry.finallyLoc) return handle(entry.finallyLoc);
            } else if (hasCatch) {
              if (this.prev < entry.catchLoc) return handle(entry.catchLoc, !0);
            } else {
              if (!hasFinally) throw new Error("try statement without catch or finally");
              if (this.prev < entry.finallyLoc) return handle(entry.finallyLoc);
            }
          }
        }
      },
      abrupt: function (type, arg) {
        for (var i = this.tryEntries.length - 1; i >= 0; --i) {
          var entry = this.tryEntries[i];
          if (entry.tryLoc <= this.prev && hasOwn.call(entry, "finallyLoc") && this.prev < entry.finallyLoc) {
            var finallyEntry = entry;
            break;
          }
        }
        finallyEntry && ("break" === type || "continue" === type) && finallyEntry.tryLoc <= arg && arg <= finallyEntry.finallyLoc && (finallyEntry = null);
        var record = finallyEntry ? finallyEntry.completion : {};
        return record.type = type, record.arg = arg, finallyEntry ? (this.method = "next", this.next = finallyEntry.finallyLoc, ContinueSentinel) : this.complete(record);
      },
      complete: function (record, afterLoc) {
        if ("throw" === record.type) throw record.arg;
        return "break" === record.type || "continue" === record.type ? this.next = record.arg : "return" === record.type ? (this.rval = this.arg = record.arg, this.method = "return", this.next = "end") : "normal" === record.type && afterLoc && (this.next = afterLoc), ContinueSentinel;
      },
      finish: function (finallyLoc) {
        for (var i = this.tryEntries.length - 1; i >= 0; --i) {
          var entry = this.tryEntries[i];
          if (entry.finallyLoc === finallyLoc) return this.complete(entry.completion, entry.afterLoc), resetTryEntry(entry), ContinueSentinel;
        }
      },
      catch: function (tryLoc) {
        for (var i = this.tryEntries.length - 1; i >= 0; --i) {
          var entry = this.tryEntries[i];
          if (entry.tryLoc === tryLoc) {
            var record = entry.completion;
            if ("throw" === record.type) {
              var thrown = record.arg;
              resetTryEntry(entry);
            }
            return thrown;
          }
        }
        throw new Error("illegal catch attempt");
      },
      delegateYield: function (iterable, resultName, nextLoc) {
        return this.delegate = {
          iterator: values(iterable),
          resultName: resultName,
          nextLoc: nextLoc
        }, "next" === this.method && (this.arg = undefined), ContinueSentinel;
      }
    }, exports;
  }
  function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
      var info = gen[key](arg);
      var value = info.value;
    } catch (error) {
      reject(error);
      return;
    }
    if (info.done) {
      resolve(value);
    } else {
      Promise.resolve(value).then(_next, _throw);
    }
  }
  function _asyncToGenerator(fn) {
    return function () {
      var self = this,
        args = arguments;
      return new Promise(function (resolve, reject) {
        var gen = fn.apply(self, args);
        function _next(value) {
          asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
        }
        function _throw(err) {
          asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
        }
        _next(undefined);
      });
    };
  }
  function _extends() {
    _extends = Object.assign ? Object.assign.bind() : function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
      return target;
    };
    return _extends.apply(this, arguments);
  }
  function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(o);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
  }
  function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
    return arr2;
  }
  function _createForOfIteratorHelperLoose(o, allowArrayLike) {
    var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];
    if (it) return (it = it.call(o)).next.bind(it);
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;
      return function () {
        if (i >= o.length) return {
          done: true
        };
        return {
          done: false,
          value: o[i++]
        };
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var WRITE_ATOM = 'w';
  var RESTORE_ATOMS = 'h';

  function useResetAtom(anAtom, scope) {
    var ScopeContext = jotai.SECRET_INTERNAL_getScopeContext(scope);
    var store = react.useContext(ScopeContext).s;
    var setAtom = react.useCallback(function () {
      return store[WRITE_ATOM](anAtom, RESET);
    }, [store, anAtom]);
    return setAtom;
  }

  function useReducerAtom(anAtom, reducer, scope) {
    var _useAtom = jotai.useAtom(anAtom, scope),
      state = _useAtom[0],
      setState = _useAtom[1];
    var dispatch = react.useCallback(function (action) {
      setState(function (prev) {
        return reducer(prev, action);
      });
    }, [setState, reducer]);
    return [state, dispatch];
  }

  function atomWithReducer(initialValue, reducer) {
    var anAtom = jotai.atom(initialValue, function (get, set, action) {
      return set(anAtom, reducer(get(anAtom), action));
    });
    return anAtom;
  }

  function atomFamily(initializeAtom, areEqual) {
    var shouldRemove = null;
    var atoms = new Map();
    var createAtom = function createAtom(param) {
      var item;
      if (areEqual === undefined) {
        item = atoms.get(param);
      } else {
        for (var _iterator = _createForOfIteratorHelperLoose(atoms), _step; !(_step = _iterator()).done;) {
          var _step$value = _step.value,
            key = _step$value[0],
            value = _step$value[1];
          if (areEqual(key, param)) {
            item = value;
            break;
          }
        }
      }
      if (item !== undefined) {
        if (shouldRemove != null && shouldRemove(item[1], param)) {
          createAtom.remove(param);
        } else {
          return item[0];
        }
      }
      var newAtom = initializeAtom(param);
      atoms.set(param, [newAtom, Date.now()]);
      return newAtom;
    };
    createAtom.remove = function (param) {
      if (areEqual === undefined) {
        atoms.delete(param);
      } else {
        for (var _iterator2 = _createForOfIteratorHelperLoose(atoms), _step2; !(_step2 = _iterator2()).done;) {
          var _step2$value = _step2.value,
            key = _step2$value[0];
          if (areEqual(key, param)) {
            atoms.delete(key);
            break;
          }
        }
      }
    };
    createAtom.setShouldRemove = function (fn) {
      shouldRemove = fn;
      if (!shouldRemove) return;
      for (var _iterator3 = _createForOfIteratorHelperLoose(atoms), _step3; !(_step3 = _iterator3()).done;) {
        var _step3$value = _step3.value,
          key = _step3$value[0],
          value = _step3$value[1];
        if (shouldRemove(value[1], key)) {
          atoms.delete(key);
        }
      }
    };
    return createAtom;
  }

  var getWeakCacheItem = function getWeakCacheItem(cache, deps) {
    do {
      var _deps = deps,
        dep = _deps[0],
        rest = _deps.slice(1);
      var entry = cache.get(dep);
      if (!entry) {
        return;
      }
      if (!rest.length) {
        return entry[1];
      }
      cache = entry[0];
      deps = rest;
    } while (deps.length);
  };
  var setWeakCacheItem = function setWeakCacheItem(cache, deps, item) {
    do {
      var _deps2 = deps,
        dep = _deps2[0],
        rest = _deps2.slice(1);
      var entry = cache.get(dep);
      if (!entry) {
        entry = [new WeakMap()];
        cache.set(dep, entry);
      }
      if (!rest.length) {
        entry[1] = item;
        return;
      }
      cache = entry[0];
      deps = rest;
    } while (deps.length);
  };
  var createMemoizeAtom = function createMemoizeAtom() {
    var cache = new WeakMap();
    var memoizeAtom = function memoizeAtom(createAtom, deps) {
      var cachedAtom = getWeakCacheItem(cache, deps);
      if (cachedAtom) {
        return cachedAtom;
      }
      var createdAtom = createAtom();
      setWeakCacheItem(cache, deps, createdAtom);
      return createdAtom;
    };
    return memoizeAtom;
  };

  var memoizeAtom$4 = createMemoizeAtom();
  function selectAtom(anAtom, selector, equalityFn) {
    if (equalityFn === void 0) {
      equalityFn = Object.is;
    }
    return memoizeAtom$4(function () {
      var refAtom = jotai.atom(function () {
        return {};
      });
      var derivedAtom = jotai.atom(function (get) {
        var slice = selector(get(anAtom));
        var ref = get(refAtom);
        if ('prev' in ref && equalityFn(ref.prev, slice)) {
          return ref.prev;
        }
        ref.prev = slice;
        return slice;
      });
      return derivedAtom;
    }, [anAtom, selector, equalityFn]);
  }

  function useAtomCallback(callback, scope) {
    var anAtom = react.useMemo(function () {
      return jotai.atom(null, function (get, set, _ref) {
        var arg = _ref[0],
          resolve = _ref[1],
          reject = _ref[2];
        try {
          resolve(callback(get, set, arg));
        } catch (e) {
          reject(e);
        }
      });
    }, [callback]);
    var invoke = jotai.useSetAtom(anAtom, scope);
    return react.useCallback(function (arg) {
      var isSync = true;
      var settled = {};
      var promise = new Promise(function (resolve, reject) {
        invoke([arg, function (v) {
          if (isSync) {
            settled = {
              v: v
            };
          } else {
            resolve(v);
          }
        }, function (e) {
          if (isSync) {
            settled = {
              e: e
            };
          } else {
            reject(e);
          }
        }]);
      });
      isSync = false;
      if ('e' in settled) {
        throw settled.e;
      }
      if ('v' in settled) {
        return settled.v;
      }
      return promise;
    }, [invoke]);
  }

  var memoizeAtom$3 = createMemoizeAtom();
  var deepFreeze = function deepFreeze(obj) {
    if (typeof obj !== 'object' || obj === null) return;
    Object.freeze(obj);
    var propNames = Object.getOwnPropertyNames(obj);
    for (var _iterator = _createForOfIteratorHelperLoose(propNames), _step; !(_step = _iterator()).done;) {
      var name = _step.value;
      var value = obj[name];
      deepFreeze(value);
    }
    return obj;
  };
  function freezeAtom(anAtom) {
    return memoizeAtom$3(function () {
      var frozenAtom = jotai.atom(function (get) {
        return deepFreeze(get(anAtom));
      }, function (_get, set, arg) {
        return set(anAtom, arg);
      });
      return frozenAtom;
    }, [anAtom]);
  }
  function freezeAtomCreator(createAtom) {
    return function () {
      var anAtom = createAtom.apply(void 0, arguments);
      var origRead = anAtom.read;
      anAtom.read = function (get) {
        return deepFreeze(origRead(get));
      };
      return anAtom;
    };
  }

  var memoizeAtom$2 = createMemoizeAtom();
  var isWritable = function isWritable(atom) {
    return !!atom.write;
  };
  var isFunction = function isFunction(x) {
    return typeof x === 'function';
  };
  function splitAtom(arrAtom, keyExtractor) {
    return memoizeAtom$2(function () {
      var mappingCache = new WeakMap();
      var getMapping = function getMapping(arr, prev) {
        var mapping = mappingCache.get(arr);
        if (mapping) {
          return mapping;
        }
        var prevMapping = prev && mappingCache.get(prev);
        var atomList = [];
        var keyList = [];
        arr.forEach(function (item, index) {
          var key = keyExtractor ? keyExtractor(item) : index;
          keyList[index] = key;
          var cachedAtom = prevMapping && prevMapping.atomList[prevMapping.keyList.indexOf(key)];
          if (cachedAtom) {
            atomList[index] = cachedAtom;
            return;
          }
          var read = function read(get) {
            var ref = get(refAtom);
            var currArr = get(arrAtom);
            var mapping = getMapping(currArr, ref.prev);
            var index = mapping.keyList.indexOf(key);
            if (index < 0 || index >= currArr.length) {
              var prevItem = arr[getMapping(arr).keyList.indexOf(key)];
              if (prevItem) {
                return prevItem;
              }
              throw new Error('splitAtom: index out of bounds for read');
            }
            return currArr[index];
          };
          var write = function write(get, set, update) {
            var ref = get(refAtom);
            var arr = get(arrAtom);
            var mapping = getMapping(arr, ref.prev);
            var index = mapping.keyList.indexOf(key);
            if (index < 0 || index >= arr.length) {
              throw new Error('splitAtom: index out of bounds for write');
            }
            var nextItem = isFunction(update) ? update(arr[index]) : update;
            set(arrAtom, [].concat(arr.slice(0, index), [nextItem], arr.slice(index + 1)));
          };
          atomList[index] = isWritable(arrAtom) ? jotai.atom(read, write) : jotai.atom(read);
        });
        if (prevMapping && prevMapping.keyList.length === keyList.length && prevMapping.keyList.every(function (x, i) {
          return x === keyList[i];
        })) {
          mapping = prevMapping;
        } else {
          mapping = {
            atomList: atomList,
            keyList: keyList
          };
        }
        mappingCache.set(arr, mapping);
        return mapping;
      };
      var refAtom = jotai.atom(function () {
        return {};
      });
      var read = function read(get) {
        var ref = get(refAtom);
        var arr = get(arrAtom);
        var mapping = getMapping(arr, ref.prev);
        ref.prev = arr;
        return mapping.atomList;
      };
      var write = function write(get, set, action) {
        if ('read' in action) {
          console.warn('atomToRemove is deprecated. use action with type');
          action = {
            type: 'remove',
            atom: action
          };
        }
        switch (action.type) {
          case 'remove':
            {
              var index = get(splittedAtom).indexOf(action.atom);
              if (index >= 0) {
                var arr = get(arrAtom);
                set(arrAtom, [].concat(arr.slice(0, index), arr.slice(index + 1)));
              }
              break;
            }
          case 'insert':
            {
              var _index = action.before ? get(splittedAtom).indexOf(action.before) : get(splittedAtom).length;
              if (_index >= 0) {
                var _arr = get(arrAtom);
                set(arrAtom, [].concat(_arr.slice(0, _index), [action.value], _arr.slice(_index)));
              }
              break;
            }
          case 'move':
            {
              var index1 = get(splittedAtom).indexOf(action.atom);
              var index2 = action.before ? get(splittedAtom).indexOf(action.before) : get(splittedAtom).length;
              if (index1 >= 0 && index2 >= 0) {
                var _arr2 = get(arrAtom);
                if (index1 < index2) {
                  set(arrAtom, [].concat(_arr2.slice(0, index1), _arr2.slice(index1 + 1, index2), [_arr2[index1]], _arr2.slice(index2)));
                } else {
                  set(arrAtom, [].concat(_arr2.slice(0, index2), [_arr2[index1]], _arr2.slice(index2, index1), _arr2.slice(index1 + 1)));
                }
              }
              break;
            }
        }
      };
      var splittedAtom = isWritable(arrAtom) ? jotai.atom(read, write) : jotai.atom(read);
      return splittedAtom;
    }, keyExtractor ? [arrAtom, keyExtractor] : [arrAtom]);
  }

  function atomWithDefault(getDefault) {
    var EMPTY = Symbol();
    var overwrittenAtom = jotai.atom(EMPTY);
    var anAtom = jotai.atom(function (get) {
      var overwritten = get(overwrittenAtom);
      if (overwritten !== EMPTY) {
        return overwritten;
      }
      return getDefault(get);
    }, function (get, set, update) {
      if (update === RESET) {
        return set(overwrittenAtom, EMPTY);
      }
      return set(overwrittenAtom, typeof update === 'function' ? update(get(anAtom)) : update);
    });
    return anAtom;
  }

  var memoizeAtom$1 = createMemoizeAtom();
  var emptyArrayAtom = jotai.atom(function () {
    return [];
  });
  function waitForAll(atoms) {
    var createAtom = function createAtom() {
      var unwrappedAtoms = unwrapAtoms(atoms);
      var derivedAtom = jotai.atom(function (get) {
        var promises = [];
        var values = unwrappedAtoms.map(function (anAtom, index) {
          try {
            return get(anAtom);
          } catch (e) {
            if (e instanceof Promise) {
              promises[index] = e;
            } else {
              throw e;
            }
          }
        });
        if (promises.length) {
          throw Promise.all(promises);
        }
        return wrapResults(atoms, values);
      });
      return derivedAtom;
    };
    if (Array.isArray(atoms)) {
      if (atoms.length) {
        return memoizeAtom$1(createAtom, atoms);
      }
      return emptyArrayAtom;
    }
    return createAtom();
  }
  var unwrapAtoms = function unwrapAtoms(atoms) {
    return Array.isArray(atoms) ? atoms : Object.getOwnPropertyNames(atoms).map(function (key) {
      return atoms[key];
    });
  };
  var wrapResults = function wrapResults(atoms, results) {
    return Array.isArray(atoms) ? results : Object.getOwnPropertyNames(atoms).reduce(function (out, key, idx) {
      var _extends2;
      return _extends({}, out, (_extends2 = {}, _extends2[key] = results[idx], _extends2));
    }, {});
  };

  var NO_STORAGE_VALUE = Symbol();
  function createJSONStorage(getStringStorage) {
    var lastStr;
    var lastValue;
    var storage = {
      getItem: function getItem(key) {
        var _getStringStorage$get, _getStringStorage;
        var parse = function parse(str) {
          str = str || '';
          if (lastStr !== str) {
            try {
              lastValue = JSON.parse(str);
            } catch (_unused) {
              return NO_STORAGE_VALUE;
            }
            lastStr = str;
          }
          return lastValue;
        };
        var str = (_getStringStorage$get = (_getStringStorage = getStringStorage()) == null ? void 0 : _getStringStorage.getItem(key)) != null ? _getStringStorage$get : null;
        if (str instanceof Promise) {
          return str.then(parse);
        }
        return parse(str);
      },
      setItem: function setItem(key, newValue) {
        var _getStringStorage2;
        return (_getStringStorage2 = getStringStorage()) == null ? void 0 : _getStringStorage2.setItem(key, JSON.stringify(newValue));
      },
      removeItem: function removeItem(key) {
        var _getStringStorage3;
        return (_getStringStorage3 = getStringStorage()) == null ? void 0 : _getStringStorage3.removeItem(key);
      }
    };
    if (typeof window !== 'undefined' && typeof window.addEventListener === 'function') {
      storage.subscribe = function (key, callback) {
        var storageEventCallback = function storageEventCallback(e) {
          if (e.key === key && e.newValue) {
            callback(JSON.parse(e.newValue));
          }
        };
        window.addEventListener('storage', storageEventCallback);
        return function () {
          window.removeEventListener('storage', storageEventCallback);
        };
      };
    }
    return storage;
  }
  var defaultStorage = createJSONStorage(function () {
    return typeof window !== 'undefined' ? window.localStorage : undefined;
  });
  function atomWithStorage(key, initialValue, storage) {
    if (storage === void 0) {
      storage = defaultStorage;
    }
    var getInitialValue = function getInitialValue() {
      var value = storage.getItem(key);
      if (value instanceof Promise) {
        return value.then(function (v) {
          return v === NO_STORAGE_VALUE ? initialValue : v;
        });
      }
      return value === NO_STORAGE_VALUE ? initialValue : value;
    };
    var baseAtom = jotai.atom(storage.delayInit ? initialValue : getInitialValue());
    baseAtom.onMount = function (setAtom) {
      var unsub;
      if (storage.subscribe) {
        unsub = storage.subscribe(key, setAtom);
        setAtom(getInitialValue());
      }
      if (storage.delayInit) {
        var _value = getInitialValue();
        if (_value instanceof Promise) {
          _value.then(setAtom);
        } else {
          setAtom(_value);
        }
      }
      return unsub;
    };
    var anAtom = jotai.atom(function (get) {
      return get(baseAtom);
    }, function (get, set, update) {
      var nextValue = typeof update === 'function' ? update(get(baseAtom)) : update;
      if (nextValue === RESET) {
        set(baseAtom, initialValue);
        return storage.removeItem(key);
      }
      set(baseAtom, nextValue);
      return storage.setItem(key, nextValue);
    });
    return anAtom;
  }

  function atomWithHash(key, initialValue, options) {
    var serialize = (options == null ? void 0 : options.serialize) || JSON.stringify;
    var deserialize = (options == null ? void 0 : options.deserialize) || function (str) {
      try {
        return JSON.parse(str || '');
      } catch (_unused2) {
        return NO_STORAGE_VALUE;
      }
    };
    var _subscribe = (options == null ? void 0 : options.subscribe) || function (callback) {
      window.addEventListener('hashchange', callback);
      return function () {
        window.removeEventListener('hashchange', callback);
      };
    };
    var hashStorage = _extends({
      getItem: function getItem(key) {
        if (typeof location === 'undefined') {
          return NO_STORAGE_VALUE;
        }
        var searchParams = new URLSearchParams(location.hash.slice(1));
        var storedValue = searchParams.get(key);
        return deserialize(storedValue);
      },
      setItem: function setItem(key, newValue) {
        var searchParams = new URLSearchParams(location.hash.slice(1));
        searchParams.set(key, serialize(newValue));
        if (options != null && options.replaceState) {
          history.replaceState(null, '', location.pathname + location.search + '#' + searchParams.toString());
        } else {
          location.hash = searchParams.toString();
        }
      },
      removeItem: function removeItem(key) {
        var searchParams = new URLSearchParams(location.hash.slice(1));
        searchParams.delete(key);
        if (options != null && options.replaceState) {
          history.replaceState(null, '', location.pathname + location.search + '#' + searchParams.toString());
        } else {
          location.hash = searchParams.toString();
        }
      }
    }, (options == null ? void 0 : options.delayInit) && {
      delayInit: true
    }, {
      subscribe: function subscribe(key, setValue) {
        var callback = function callback() {
          var searchParams = new URLSearchParams(location.hash.slice(1));
          var str = searchParams.get(key);
          if (str !== null) {
            setValue(deserialize(str));
          } else {
            setValue(initialValue);
          }
        };
        return _subscribe(callback);
      }
    });
    return atomWithStorage(key, initialValue, hashStorage);
  }

  function atomWithObservable(getObservable, options) {
    var observableResultAtom = jotai.atom(function (get) {
      var _observable$Symbol$ob, _observable;
      var observable = getObservable(get);
      var itself = (_observable$Symbol$ob = (_observable = observable)[Symbol.observable]) == null ? void 0 : _observable$Symbol$ob.call(_observable);
      if (itself) {
        observable = itself;
      }
      var resolve;
      var makePending = function makePending() {
        return new Promise(function (r) {
          resolve = r;
        });
      };
      var initialResult = options && 'initialValue' in options ? {
        d: typeof options.initialValue === 'function' ? options.initialValue() : options.initialValue
      } : makePending();
      var setResult;
      var lastResult;
      var listener = function listener(result) {
        lastResult = result;
        resolve == null ? void 0 : resolve(result);
        setResult == null ? void 0 : setResult(result);
      };
      var subscription;
      var timer;
      var isNotMounted = function isNotMounted() {
        return !setResult;
      };
      var start = function start() {
        if (subscription) {
          clearTimeout(timer);
          subscription.unsubscribe();
        }
        subscription = observable.subscribe({
          next: function next(d) {
            return listener({
              d: d
            });
          },
          error: function error(e) {
            return listener({
              e: e
            });
          },
          complete: function complete() {}
        });
        if (isNotMounted() && options != null && options.unstable_timeout) {
          timer = setTimeout(function () {
            if (subscription) {
              subscription.unsubscribe();
              subscription = undefined;
            }
          }, options.unstable_timeout);
        }
      };
      start();
      var resultAtom = jotai.atom(lastResult || initialResult);
      resultAtom.onMount = function (update) {
        setResult = update;
        if (lastResult) {
          update(lastResult);
        }
        if (subscription) {
          clearTimeout(timer);
        } else {
          start();
        }
        return function () {
          setResult = undefined;
          if (subscription) {
            subscription.unsubscribe();
            subscription = undefined;
          }
        };
      };
      return [resultAtom, observable, makePending, start, isNotMounted];
    });
    var observableAtom = jotai.atom(function (get) {
      var _get = get(observableResultAtom),
        resultAtom = _get[0];
      var result = get(resultAtom);
      if ('e' in result) {
        throw result.e;
      }
      return result.d;
    }, function (get, set, data) {
      var _get2 = get(observableResultAtom),
        resultAtom = _get2[0],
        observable = _get2[1],
        makePending = _get2[2],
        start = _get2[3],
        isNotMounted = _get2[4];
      if ('next' in observable) {
        if (isNotMounted()) {
          set(resultAtom, makePending());
          start();
        }
        observable.next(data);
      } else {
        throw new Error('observable is not subject');
      }
    });
    return observableAtom;
  }

  var hydratedMap = new WeakMap();
  function useHydrateAtoms(values, scope) {
    var ScopeContext = jotai.SECRET_INTERNAL_getScopeContext(scope);
    var scopeContainer = react.useContext(ScopeContext);
    var store = scopeContainer.s;
    var hydratedSet = getHydratedSet(scopeContainer);
    var tuplesToRestore = [];
    for (var _iterator = _createForOfIteratorHelperLoose(values), _step; !(_step = _iterator()).done;) {
      var tuple = _step.value;
      var atom = tuple[0];
      if (!hydratedSet.has(atom)) {
        hydratedSet.add(atom);
        tuplesToRestore.push(tuple);
      }
    }
    if (tuplesToRestore.length) {
      store[RESTORE_ATOMS](tuplesToRestore);
    }
  }
  function getHydratedSet(scopeContainer) {
    var hydratedSet = hydratedMap.get(scopeContainer);
    if (!hydratedSet) {
      hydratedSet = new WeakSet();
      hydratedMap.set(scopeContainer, hydratedSet);
    }
    return hydratedSet;
  }

  var memoizeAtom = createMemoizeAtom();
  var LOADING = {
    state: 'loading'
  };
  function loadable(anAtom) {
    return memoizeAtom(function () {
      var loadableAtomCache = new WeakMap();
      var catchAtom = jotai.atom(function (get) {
        var promise;
        try {
          var data = get(anAtom);
          var _loadableAtom = jotai.atom({
            state: 'hasData',
            data: data
          });
          return _loadableAtom;
        } catch (error) {
          if (error instanceof Promise) {
            promise = error;
          } else {
            var _loadableAtom2 = jotai.atom({
              state: 'hasError',
              error: error
            });
            return _loadableAtom2;
          }
        }
        var cached = loadableAtomCache.get(promise);
        if (cached) {
          return cached;
        }
        var loadableAtom = jotai.atom(LOADING, function () {
          var _ref = _asyncToGenerator(_regeneratorRuntime().mark(function _callee(get, set) {
            var _data;
            return _regeneratorRuntime().wrap(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    _context.prev = 0;
                    _context.next = 3;
                    return get(anAtom, {
                      unstable_promise: true
                    });
                  case 3:
                    _data = _context.sent;
                    set(loadableAtom, {
                      state: 'hasData',
                      data: _data
                    });
                    _context.next = 10;
                    break;
                  case 7:
                    _context.prev = 7;
                    _context.t0 = _context["catch"](0);
                    set(loadableAtom, {
                      state: 'hasError',
                      error: _context.t0
                    });
                  case 10:
                  case "end":
                    return _context.stop();
                }
              }
            }, _callee, null, [[0, 7]]);
          }));
          return function (_x, _x2) {
            return _ref.apply(this, arguments);
          };
        }());
        loadableAtom.onMount = function (init) {
          init();
        };
        loadableAtomCache.set(promise, loadableAtom);
        return loadableAtom;
      });
      var derivedAtom = jotai.atom(function (get) {
        var loadableAtom = get(catchAtom);
        return get(loadableAtom);
      });
      return derivedAtom;
    }, [anAtom]);
  }

  function abortableAtom(read, write) {
    return jotai.atom(function (get) {
      var controller = new AbortController();
      var promise = read(get, {
        signal: controller.signal
      });
      if (promise instanceof Promise) {
        jotai.SECRET_INTERNAL_registerPromiseAbort(promise, function () {
          return controller.abort();
        });
      }
      return promise;
    }, write);
  }

  Object.defineProperty(exports, 'useAtomValue', {
    enumerable: true,
    get: function () { return jotai.useAtomValue; }
  });
  Object.defineProperty(exports, 'useUpdateAtom', {
    enumerable: true,
    get: function () { return jotai.useSetAtom; }
  });
  exports.RESET = RESET;
  exports.abortableAtom = abortableAtom;
  exports.atomFamily = atomFamily;
  exports.atomWithDefault = atomWithDefault;
  exports.atomWithHash = atomWithHash;
  exports.atomWithObservable = atomWithObservable;
  exports.atomWithReducer = atomWithReducer;
  exports.atomWithReset = atomWithReset;
  exports.atomWithStorage = atomWithStorage;
  exports.createJSONStorage = createJSONStorage;
  exports.freezeAtom = freezeAtom;
  exports.freezeAtomCreator = freezeAtomCreator;
  exports.loadable = loadable;
  exports.selectAtom = selectAtom;
  exports.splitAtom = splitAtom;
  exports.unstable_NO_STORAGE_VALUE = NO_STORAGE_VALUE;
  exports.useAtomCallback = useAtomCallback;
  exports.useHydrateAtoms = useHydrateAtoms;
  exports.useReducerAtom = useReducerAtom;
  exports.useResetAtom = useResetAtom;
  exports.waitForAll = waitForAll;

}));


 })(window);